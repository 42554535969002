<template>
  <Modal :value="visible"
         :title="title"
         width="900"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="drawer">
      <div class="drawer-cont">
        <div class="drawer-cont-right">
          <div id="enclosureModalMapDiv"
               class="mapDiv"
               ref="enclosureModalMapDiv">
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tMap from '@/utils/tMap'
import MyTreeSelect from '@/components/common/MyTreeSelect';
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '网格'
    },
    dataId: String,
  },
  data () {
    return {
      height: 0,
      data: {},
      map: null,  // 天地图实例
      zoom: 14, // 初始地图缩放等级
      config: {
        color: "#0079fe",
        weight: 3,
        opacity: 0.8,
        fillColor: "#0079fe",
        fillOpacity: 0.3
      },
      sectionArr: []
    };
  },
  watch: {
    visible (newVal) {
      newVal && this.init()
    },
    dataId (newValue) {
      newValue && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'mapCenterLnglat': 'getMapCenterLnglat',
    }),
  },
  mounted () {
    this.height = window.innerHeight - 160
    this.initData()
  },
  methods: {
    init () {
      if (!this.map) this.createMap()
    },
    initData () {
      this.$http.getGridTreeList({ type: 3 }).then(res => {
        if (res.code === 200) {
          this.sectionArr = res.result
        }
      })
    },
    getDetail () {
      this.$store.dispatch('getGridDetail', { gridId: this.dataId }).then(res => {
        this.data = res
        let viewport
        let overlay
        switch (res.drawType) {
          case 3:
            // 圆形
            this.data.points = new T.LngLat(res.lng, res.lat)
            overlay = new T.Circle(this.data.points, res.radius, this.config)
            this.map.addOverLay(overlay)
            // viewport = this.map.getViewport([this.data.points])
            // this.map.panTo(viewport.center, viewport.zoom)
            let bounds = overlay.getBounds()
            viewport = this.map.getViewport([bounds.Lq, bounds.kq])
            this.map.panTo(viewport.center, viewport.zoom)
            break;
          case 4:
            // 矩形
            this.data.points = JSON.parse(res.points)
            this.map.addOverLay(new T.Rectangle([this.data.points.Lq, this.data.points.kq], this.config))
            viewport = this.map.getViewport([this.data.points.Lq, this.data.points.kq])
            this.map.panTo(viewport.center, viewport.zoom)
            break;
          case 5:
            // 多边形
            this.data.points = JSON.parse(res.points)
            overlay = new T.Polygon(this.data.points, this.config)
            this.map.addOverLay(overlay)
            let lnglatList = [];
            this.data.points.forEach(pList => {
              pList.forEach(p => {
                let lnglat = new T.LngLat(p.lng, p.lat)
                lnglatList.push(lnglat)
              })
            })
            viewport = this.map.getViewport(lnglatList)
            this.map.panTo(viewport.center, viewport.zoom)
            break;
          case 6:
            // 线
            this.data.points = JSON.parse(res.points)
            this.map.addOverLay(new T.Polyline(this.data.points, this.config))
            viewport = this.map.getViewport(this.data.points)
            this.map.panTo(viewport.center, viewport.zoom)
            break;
          default:
            // 多路段组成的路线 - 画网格
            let sectionIdList = res.sectionId.split(",")
            let selectSectionList = this.getSelectSectionList(sectionIdList, this.sectionArr)
            let totalPoints = []
            selectSectionList.forEach(section => {
              let points = JSON.parse(section.lnglat)
              this.overlay = new T.Polyline(points, this.config)
              this.map.addOverLay(this.overlay)
              points.forEach(p => {
                totalPoints.push(p)
              })
            })
            let viewport = this.map.getViewport(totalPoints)
            this.map.panTo(viewport.center, viewport.zoom)
            break;
        }
        switch (res.type) {
          case 1:
            this.data.type = '区域'
            break;
          case 2:
            this.data.type = '线路'
            break;
          case 3:
            this.data.type = '路段'
            break;
          default:
            break;
        }
        this.data.status = res.status === 1 ? '启用' : '禁用'
        this.data.guideboard = res.guideboard === 1 ? '是' : '否'
      })
    },
    getSelectSectionList (sectionIdList, sectionArr) {
      let result = []
      sectionArr.forEach(item => {
        if (sectionIdList.includes(item.id)) {
          let resultItem = { km: item.km, area: item.area, lnglat: item.lnglat }
          result.push(resultItem)
        }
        if (item.children && item.children.length > 0) {
          this.getSelectSectionList(sectionIdList, item.children).forEach(i => {
            result.push(i)
          })
        }
      })
      return result;
    },
    // 生成地图
    createMap () {
      tMap.init().then((T) => {
        this.map = new T.Map('enclosureModalMapDiv')
        let lnglat
        if (this.mapCenterLnglat) {
          let mapCenterLnglat = this.mapCenterLnglat.split(',')
          lnglat = new T.LngLat(mapCenterLnglat[0], mapCenterLnglat[1])
        } else {
          lnglat = new T.LngLat(119.130974, 36.706688)
        }
        this.map.centerAndZoom(lnglat, this.zoom)
      }).catch(err => {
        console.log(err);
        this.$Message.info('地图加载失败,请刷新浏览器')
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('update:dataId', '')
        this.$emit('onChange', false)
      }
    },
    resetData () {
      this.data = {}
      this.map.clearOverLays()
    }
  },
}
</script>


<style lang='scss' scoped>
::v-deep {
  .ivu-drawer-wrap {
    z-index: 2000;
  }
  .ivu-drawer {
    top: 60px;
    bottom: 0;
    .ivu-drawer-body {
      padding: 0;
    }
  }
  .modal-cont .form-ul .form-ul-li .ivu-checkbox-default {
    line-height: 1;
  }
}
.modal-cont {
  max-height: none;
}
.drawer {
  height: 600px;
  display: flex;
  flex-direction: column;
  .drawer-cont {
    flex: 1;
    display: flex;
    .drawer-cont-left {
      width: 480px;
      // height: 100%;
      padding: 0 10px;
      // overflow: auto;
      .li-color {
        display: flex;
        align-items: center;
        li {
          width: 22px;
          height: 22px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            width: 15px;
            height: 15px;
            cursor: pointer;
            display: flex;
          }
        }
        li:nth-child(1) span {
          border: 1px solid #1f86ea;
          background: rgba(31, 134, 234, 0.2);
        }
        li:nth-child(2) span {
          border: 1px solid #129b3a;
          background: rgba(18, 155, 58, 0.2);
        }
        li:nth-child(3) span {
          border: 1px solid rgb(244, 147, 58);
          background: rgba(244, 147, 58, 0.2);
        }
        li:nth-child(4) span {
          border: 1px solid rgb(71, 85, 238);
          background: rgba(71, 85, 238, 0.2);
        }
        li:nth-child(5) span {
          border: 1px solid rgb(244, 57, 57);
          background: rgba(244, 57, 57, 0.2);
        }
        .current {
          background: #bbdaf9;
        }
      }
    }
    .drawer-cont-right {
      flex: 1;
      position: relative;
      .mapDiv {
        width: 100%;
        height: 100%;
      }
      .map-top {
        display: flex;
        z-index: 500;
        position: absolute;
        top: 20px;
        left: 20px;
        .map-top-btns {
          margin-left: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          color: #000;
          border-radius: 4px;
          background: #ffffff;
          box-shadow: 0 2px 1px 0 rgba(31, 132, 235, 0.2);
          span {
            margin-right: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            i {
              margin-right: 4px;
            }
          }
          span:last-child {
            margin-right: 0;
          }
          .current {
            color: #0079fe;
          }
        }
      }
      .map-bottom {
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        color: #000;
        background: #ffffff;
        box-shadow: 0 2px 1px 0 rgba(31, 132, 235, 0.2);
        z-index: 500;
        position: absolute;
        bottom: 20px;
        left: 20px;
        span {
          margin-right: 4px;
        }
      }
    }
  }
  .drawer-footer {
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    border-top: 1px solid #e1e3e5;
    text-align: right;
    padding: 0 20px;
    button {
      margin-left: 6px;
    }
  }
}
.map-grid {
  ::v-deep {
    .ivu-drawer {
      top: 0;
    }
  }
  .drawer {
    height: calc(100%);
  }
}
</style>