// 导出模板
<template>
  <div @click="onClickExport">
    <slot></slot>
  </div>
</template>

<script>
import { download } from '@/utils/utils'
export default {
  props: {
    path: Function,
    data: Object,
    exportType: {
      type: Number,
      default: 0,   // 0文件 1模板
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onClickExport () {
      if (this.exportType == 0) {
        this.exportFile()
      } else {
        this.exportTemFile()
      }
    },
    // 导出文件
    exportFile () {
      this.path(this.data).then((res) => {
        if (res.code === 200) {
          download(res.message)
        } else {
          this.$Message.info(res.message)
        }
      }).catch(err => {
        this.$Message.info(res.message)
      })
    },
    // 导出模板
    exportTemFile () {
      this.path(this.data).then((res) => {
        if (res.code === 200) {
          download(res.message)
        } else {
          this.$Message.info(res.message)
        }
      }).catch(err => {
        this.$Message.info(res.message)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  display: none;
}
</style>
